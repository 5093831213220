import {Config} from "../config/Config";

export class GetUrl {
    private readonly config: Config;
    constructor(config: Config) {
        this.config = config;
    }

    public api(endpoint: string = ''): string {
        const apiConfig = this.config.getApi();
        const clearedEndPoint = this.clearEndpoint(endpoint);
        return `${apiConfig.baseUrl}api/v1/${clearedEndPoint}`;
    }

    private clearEndpoint(endpoint: string): string {
        return endpoint.endsWith('/') ? endpoint.slice(-1) : endpoint;
    }
}
