import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import '../css/Location.css';
import '../css/Text.css';
import Image from "react-bootstrap/Image";


export class Location extends React.Component {
    render() {
        return (
            <Container className={"component"}>
                <Row>
                    <Col className={"location-col"}>
                        <Image className={"component-image"} src="../../imgs/location-img.jpg"/>
                    </Col>
                </Row>

                <Row className={"absolute-pos location-title-row"}>
                    <Col className={"location-col-title location-title"}>
                        Локация
                    </Col>
                </Row>

                <Row className={"absolute-pos location-btns-row"}>
                    <Col className={"location-col"}>
                        <a target={"_blank"} href="https://maps.app.goo.gl/vdGcZp8zaouWHe5v8" className={"location-btn"}>The Hill</a>
                    </Col>
                    <Col className={"location-col"}>
                        <a target={"_blank"} href="https://t.me/thehillcy/743" className={"location-btn"}>Парковка</a>
                    </Col>
                </Row>
            </Container>
        );
    }
}
