import React from "react";
import {Config} from "../config/Config";
import {GetUrl} from "../utils/GetUrl";
import {Helmet} from 'react-helmet';

import axios from "axios";

interface IState {
    guestName: string;
}

export class Meta extends React.Component<{}, IState> {
    async getGuestNames(guestId: string): Promise<string> {
        try {
            const config = Config.get();
            const apiUrl = new GetUrl(config)
            const request = await axios.post(apiUrl.api('guest/get'), {
                guestId: guestId
            })

            return request.data.guestName;
        } catch (exception) {
            console.error(exception);
            return '';
        }
    }

    render() {
        if (this.state?.guestName) {
            return (
                <Helmet>
                    <title>Wedding day Ekaterina & Nikita</title>
                    <meta name="description" content={this.state.guestName + ' будем рады видеть вас на нашей свадьбе!'} />
                </Helmet>
            )
        } else {
            return (
                <Helmet>
                    <title>Wedding day Ekaterina & Nikita</title>
                    <meta name="description" content={""} />
                </Helmet>
            )
        }
    }

    async componentDidMount() {
        const guestName = await this.getGuestNames(this.getQueryGuestId());
        this.setState({
            guestName: guestName
        })
    }

    getQueryGuestId(): string {
        const queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('id') ?? '';
    }
}
