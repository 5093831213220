import React from "react";
import Image from 'react-bootstrap/Image';
import {Col, Container, Row} from "react-bootstrap";
import '../css/Welcome.css';


export class Welcome extends React.Component {
    render() {
        return (
            <Container className={"component"}>
                <Row>
                    <Col className={"welcome-col"}>
                        <Image className={"component-image"} src="../../imgs/first.jpg"/>
                        <div className={"component-text-over-img main-title"}>Никита & Екатерина</div>
                        <div className={"component-date-over-img main-title"}>19.07.2024</div>
                    </Col>
                </Row>
            </Container>
        );
    }
}
