import React from "react";
import {Config} from "../config/Config";
import {GetUrl} from "../utils/GetUrl";
import {Helmet} from 'react-helmet';

import axios from "axios";

interface IProps {
    guestId: string
}

interface IState {
    guestName: string;
}

export class GuestName extends React.Component<IProps, IState>{
    async getGuestNames(guestId: string): Promise<string> {
        try {
            const config = Config.get();
            const apiUrl = new GetUrl(config)
            const request = await axios.post(apiUrl.api('guest/get'), {
                guestId: guestId
            })

            return request.data.guestName;
        } catch (exception) {
            console.error(exception);
            return '';
        }
    }

    render() {
        if (this.state?.guestName) {
            return (
                <div>
                    {this.state.guestName}!
                </div>
            )
        } else {
            return (
                <div>
                    Дорогой гость
                </div>
            )
        }
    }

    async componentDidMount() {
        const guestName = await this.getGuestNames(this.props.guestId);
        this.setState({
            guestName: guestName
        })
    }
}
