import {IApiConfig, IConfig} from "./IConfig";
import * as config from './config.json';

export class Config {
    private static instance: Config | null = null;
    private config: IConfig;

    private constructor(config: IConfig) {
        this.config = config;
    }

    public static get(): Config {
        if (this.instance instanceof Config) {
            return this.instance;
        }

        this.instance = new Config(config);
        return this.instance
    }

    public getApi(): IApiConfig {
        return this.config?.api ?? {}
    }
}
