import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {GuestName} from "./GuestName";
import '../css/Invite.css'
import {CalendarDate} from "react-bootstrap-icons";

export class Invite extends React.Component {
    render() {
        return(
            <Container className={"component"}>
                <Row>
                    <Col className={"title invite-title justify-content-center"}>
                        <GuestName guestId={this.getQueryGuestId()}/>
                    </Col>
                </Row>
                <Row className={"invite-description"}>
                    <Col id={"pre-invite"}>
                        <div>КАЖДАЯ ИСТОРИЯ ЛЮБВИ ПРЕКРАСНА, НО НАША — МОЯ САМАЯ ЛЮБИМАЯ....</div>
                        <hr></hr>
                        <div className={"part2"}>Совсем скоро наступит один из главных дней нашей жизни - мы женимся! Будем рады, если вы присоединитесь к нам в этот особенный день.</div>
                    </Col>
                </Row>
            </Container>
        );
    }

    getQueryGuestId(): string {
        const queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('id') ?? '';
    }
}
