import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import '../css/Program.css'
import {CalendarCheck, SuitHeart} from "react-bootstrap-icons";
import {Config} from "../config/Config";
import {GetUrl} from "../utils/GetUrl";
import axios from "axios";

interface IProgram {
    time: string,
    title: string,
    description: string
}

interface IState {
    program: Array<IProgram>
}

export class Program extends React.Component<{}, IState> {
    async getProgram(id: string): Promise<Array<IProgram>> {
        try {
            const config = Config.get();
            const apiUrl = new GetUrl(config)
            const request = await axios.post(apiUrl.api('guest/program'), {
                weddingId: id
            })

            return request.data.program;
        } catch (exception) {
            console.error(exception);
            return [];
        }
    }

    render() {
        return(
            <Container className={"component program-component"}>
                <Row>
                    <Col className={"title program-title justify-content-center"}>
                        Программа
                    </Col>
                </Row>
                {this.renderProgramItems()}
            </Container>
        );
    }

    renderProgramItems(){
        if (this.state?.program) {
            return (
                <div>
                    {this.state.program.map((item: IProgram, index) => (
                        <Row id={`program-item-${index}`}>
                            <Col className={"program-item"}>
                                <SuitHeart size={30}/><br/>
                                { item.time} {item.title}
                                {index === 0 &&
                                    <div className={"program-description"}> {item.description} <a target={"_blank"} href='https://maps.app.goo.gl/7SbfCe7PC4N3CDL78' className={"program-description-a"}> <span className={"program-description-link"}>муниципалитете Гермассогеи</span></a></div>
                                }
                                {index > 0 &&
                                    <div className={"program-description"}> {item.description}</div>
                                }
                            </Col>
                        </Row>
                    ))}
                </div>
            )
        } else {
            return (<div>Program in loading</div>)
        }
    }

    async componentDidMount() {
        const program = await this.getProgram('1');
        this.setState({
            program: program
        });
    }
}
