import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import '../css/WaitForYou.css'
import Countdown from "react-countdown";
import {EnvelopeHeart} from "react-bootstrap-icons";

const Completionist = () => <span>Party start!</span>;
export class WaitForYou extends React.Component {
    render() {
        return(
            <Container className={"component program-component"}>
                <div className={"gift-list"}>
                    <div className={"gift-item"}>Не ломайте голову над подарком - мы будем рады вашим пожеланиям в конвертах!</div>
                    <Row>
                        <EnvelopeHeart size={70}/>
                    </Row>
                </div>
                <hr></hr>
                <div className={"timer-container"}>
                    <Row>
                        <Col className={"title waiting-title-waiting-title-timer"}>
                            С нетерпением ждем вас!
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"timer"}>
                            <Countdown date={Date.now() + (1721397600000 - Date.now())}>
                                <Completionist />
                            </Countdown>
                        </Col>
                    </Row>
                </div>
            </Container>
        );
    }
}
