import {Invite} from "./components/Invite";
import {Welcome} from "./components/Welcome";
import {Program} from "./components/Program";
import {Location} from "./components/Location";
import {WaitForYou} from "./components/WaitForYou";
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/ComponentsBase.css';
import './css/Text.css';
import {Meta} from "./components/Meta";

export class App extends React.Component {
    render() {
        return(
            <div id={"root"} className={"just-text"}>
                <Meta/>
                <Welcome/>
                <Invite/>
                <Program/>
                <Location/>
                <WaitForYou/>
            </div>
        )
    }
}
